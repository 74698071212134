import { ClerkProvider } from "@clerk/react-router";
import { rootAuthLoader } from "@clerk/react-router/ssr.server";
import * as Sentry from "@sentry/react-router";
import { ConfigProvider } from "antd";
import clsx from "clsx";
import { useEffect } from "react";
import {
  Links,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteLoaderData,
} from "react-router";
import getServerConfig from "server/config";
import tailwindConfig from "tailwind.config";
import type { Route } from "./+types/root";
import secondcropLogo from "./assets/compact-logo.png";
import "./app.css";
import "./styles/clerkOverrides.css";
import "./styles/sentryOverrides.css";
import "./styles/antd.css";

export function loader(args: Route.LoaderArgs) {
  return rootAuthLoader(args, () => {
    const serverConfig = getServerConfig();
    const url = new URL(args.request.url);
    const deployment: "production" | "staging" | "development" =
      serverConfig.NODE_ENV === "production"
        ? url.hostname === "app.staging.secondcrop.com"
          ? "staging"
          : "production"
        : "development";
    return {
      ENV: {
        // TODO: we could consider using VITE_ prefix and import.meta.env
        // for these values
        ARCGIS_CLIENT_API_KEY: serverConfig.ARCGIS_CLIENT_API_KEY,
        GIT_COMMIT_HASH: serverConfig.GIT_COMMIT_HASH,
        NODE_ENV: process.env.NODE_ENV,
        PANDADOC_CONTRACT_URL: serverConfig.PANDADOC_CONTRACT_URL,
        PANDADOC_EVENT_ENV: serverConfig.PANDADOC_EVENT_ENV,
        SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX:
          serverConfig.SECONDCROP_FIELD_MAP_ATTACHMENTS_MAX,
        MAPBOX_PUBLIC_KEY: serverConfig.MAPBOX_PUBLIC_KEY,
        ANALYTICS_ID: serverConfig.ANALYTICS_ID,
      },
      deployment,
    };
  });
}

export const meta: MetaFunction = () => {
  return [{ title: "SecondCrop Soil Carbon Projects | Loam Bio Australia" }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useRouteLoaderData<typeof loader>("root");

  useEffect(() => {
    if (!window.__sentryInitialized && loaderData) {
      console.log(
        `init sentry client: ${loaderData.deployment} v${loaderData.ENV.GIT_COMMIT_HASH}`,
      );

      Sentry.init({
        dsn: "https://89930e9123ea64384e24ffe46f6502cb@o4506862555365376.ingest.us.sentry.io/4507608892243968",
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        enabled:
          typeof loaderData.ENV.NODE_ENV === "string" &&
          loaderData.ENV.NODE_ENV !== "development",
        environment: loaderData.deployment,
        release: loaderData.ENV.GIT_COMMIT_HASH,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
      });

      window.__sentryInitialized = true;
    }
  }, [loaderData]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
        {loaderData && (
          <script
            // biome-ignore lint/security/noDangerouslySetInnerHtml: Migrate to biome
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify({ ...loaderData.ENV, deployment: loaderData.deployment })}`,
            }}
          />
        )}

        {loaderData && loaderData.ENV.ANALYTICS_ID.length > 0 && (
          <script
            defer
            src="https://cloud.umami.is/script.js"
            data-website-id={loaderData.ENV.ANALYTICS_ID}
          />
        )}
      </body>
    </html>
  );
}

export default function App({ loaderData }: Route.ComponentProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: tailwindConfig.theme.extend.colors.loamGreen,
        },
      }}
    >
      {/* Environment tag */}
      {["staging", "development"].includes(loaderData.deployment) && (
        <div
          className={clsx("fixed top-0 right-0 text-white px-2 py-1 z-50", {
            "bg-yellow-400": loaderData.deployment === "staging",
            "bg-green-400": loaderData.deployment === "development",
          })}
        >
          {loaderData.deployment === "staging" ? "staging" : "local"}
        </div>
      )}
      <ClerkProvider loaderData={loaderData}>
        <Outlet />
      </ClerkProvider>
    </ConfigProvider>
  );
}

export function ErrorBoundary({ error, loaderData }: Route.ErrorBoundaryProps) {
  let message = "Oops!";
  let details = "An unexpected error occurred.";
  let stack: string | undefined;
  const deployment = loaderData?.deployment;

  if (isRouteErrorResponse(error)) {
    message = error.status === 404 ? "404" : "Error";
    details =
      error.status === 404
        ? "The requested page could not be found."
        : error.statusText || details;
  } else if (error && error instanceof Error) {
    // you only want to capture non 404-errors that reach the boundary
    Sentry.captureException(error);
    if (deployment !== "production") {
      details = error.message;
      stack = error.stack;
    }
  }

  return (
    <main className="pt-16 p-4 container mx-auto">
      <div className="w-full flex flex-col items-center space-y-4">
        <img src={secondcropLogo} alt="SecondCrop" className="w-auto h-12" />
        <h1 className="font-bold text-xl">{message}</h1>
        <p className="text-lg">{details}</p>
        <a
          href="/"
          className="inline-block p-2 bg-loamGreen text-white rounded-md"
        >
          Go back to home
        </a>
      </div>

      <pre className="w-full p-4 overflow-x-auto">
        <pre>Deployment: {deployment}</pre>
        {stack && deployment !== "production" && <code>{stack}</code>}
      </pre>
    </main>
  );
}
